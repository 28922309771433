exports.strangeCompare = (a, b) => {
  if (typeof(a)!=="string" || a === '')
    return 1;
  if (typeof(b)!=="string" || b === '')
    return -1;
  if (a.includes("just now"))
    return -1;
  if (a.includes(" minutes")) {
    if (b.includes("just now"))
      return 1;
    if (b.includes(" hours"))
      return -1;
    if (b.includes(" days"))
      return -1;
    if (b.includes(" months"))
      return -1;
    if (b.includes(" years"))
      return -1;
    if (b === "")
      return -1;
    a = Number.parseInt(a);
    b = Number.parseInt(b);
  }
  else if (a.includes(" hours")) {
    if (b.includes("just now"))
      return 1;
    if (b.includes(" minutes"))
      return 1;
    if (b.includes(" days"))
      return -1;
    if (b.includes(" months"))
      return -1;
    if (b.includes(" years"))
      return -1;
    if (b === "")
      return -1;
    a = Number.parseInt(a);
    b = Number.parseInt(b);
  }
  else if (a.includes(" days")) {
    if (b.includes("just now"))
      return 1;
    if (b.includes(" minutes"))
      return 1;
    if (b.includes(" hours"))
      return 1;
    if (b.includes(" months"))
      return -1;
    if (b.includes(" years"))
      return -1;
    if (b === "")
      return -1;
      a = Number.parseInt(a);
      b = Number.parseInt(b);
  }
  else if (a.includes(" months")) {
    if (b.includes("just now"))
      return 1;
    if (b.includes(" minutes"))
      return 1;
    if (b.includes(" hours"))
      return 1;
    if (b.includes(" days"))
      return 1;
    if (b.includes(" years"))
      return -1;
    if (b === "")
      return -1;
    a = Number.parseInt(a);
    b = Number.parseInt(b);
  }
  else if (a.includes(" years")) {
    if (b.includes("just now"))
      return 1;
    if (b.includes(" minutes"))
      return 1;
    if (b.includes(" hours"))
      return 1;
    if (b.includes(" days"))
      return 1;
    if (b.includes(" months"))
      return 1;
    if (b === "")
      return -1;
    a = Number.parseInt(a);
    b = Number.parseInt(b);
  }

  if (b === NaN)
    return -1;

  return a > b ? 1 : -1;
}
