// Copyright (C) 2022, Rutio AB. All rights reserved.

import './App.css';
import React from 'react';
import {buttonStyle, widgetStyle, fontSize, smallTextStyle, deviceColor, selectColor, smallButtonStyle} from './styling';
import { TimeSpace } from './TimeSpace';
import { SideWays } from './Sideways';
import { getApi, getWsApi, isDebug } from './api';
import { Fetch } from './Fetch';
import { io } from "socket.io-client";
import { produceDeviceMarkers, produceSolutionMarkers } from "./DeviceMarkerFactory";
import { formatAge, formatDateAsAge } from './dateFormat';
import { strangeCompare } from './strangecompare';
import { isBatteryLow } from './isBatteryLow';
import { getMaxAlarmTimeS, getMaxTraceTimeS } from './limits';
import { SureYesNoButton, SureYesNoButton2 } from './ClickOnceButton';

const ACTIVATE_DEACTIVATE_TIMEOUT_S = 60;

export const Button = (props) => {
  return <button style={buttonStyle} onClick={()=>props.onClick()} disabled={props.disabled}>{props.text}</button>
}

export const SmallButton = (props) => {
  return <button style={smallButtonStyle} onClick={()=>props.onClick()} disabled={props.disabled}>{props.text}</button>
}

const Input = (props) => {
  return <div>
    <div style={{...smallTextStyle, textAlign:"left", fontStyle:"italic", marginBottom:"-2vmin"}}>{props.hint}</div>
    <input style={{...widgetStyle, width:"90%"}} 
          type={props.type} 
          value={props.value} 
          placeholder={props.placeholder}
          onChange={(e)=>props.onChange(e.target.value)}
          disabled={props.disabled}></input>
    </div>
}

const SearchBox = ({search, setSearch, spaceForFilters}) => {
  React.useEffect(() => {
    const timer = setInterval(()=>setSearch(()=>""), 60*1000);
    return ()=>clearInterval(timer);
  }, [search]);

  return <div style={{position:"fixed", left:0, width:"100vw", height:spaceForFilters ? "13vmin":"6vmin", bottom:0, background:"#fff8"}}>
    <div style={{position:"fixed", left:"33vw", width:"35vw", bottom:"0vh", }}>
              <Input placeholder="Search" value={search} onChange={setSearch} />
    </div>
  </div>
}

const Init = (props) => {
  console.log(props);
  const token = window.localStorage.getItem("token");
  return <div style={{minWidth:"50vmin"}}>
      <Logotype/>
      <hr></hr>
      <div style={widgetStyle}>
      Connecting...
      </div>
      <Fetch submit={true} 
          onError={(e)=>{props.setView("offline")}} 
          onResult={(r)=>{
            console.log(r);
            if (r.token && r.account) {
              window.localStorage.setItem("token", r.token);
              props.setAccount(r.account);
              props.setUsername(r.username); 
              props.setRights(r.rights);
              props.setView("map");
            } else {
              props.setView("login");
            }}}
          api={getApi()+`/ping?token=${ec(token)}`} />
      </div>;
} 

const ec = (s) => encodeURIComponent(s);

const Logotype = () => {
  return <img src="LifeFinder_logo_RGB.png" style={{width:"50vmin"}}/>
}

const Login = (props) => {
  const {socket} = props;
  let username0 = window.localStorage.getItem("username");
  if (!username0)
    username0 = "";
  const [submit, setSubmit] = React.useState(false);
  const [username, setUsername] = React.useState(username0);
  const [password, setPassword] = React.useState("");
  return <div style={{minWidth:"50vmin", width:"50vmin"}}>
    <Logotype />
    <hr></hr>
    <div>
    <form>
    <Input key="username" hint="Username" text={username} type="text" onChange={setUsername} disabled={submit}/>
    <Input key="password" hint="Password" text={password} type="password" onChange={setPassword} disabled={submit}/>
    </form>
    </div>
    <hr></hr>
    <Button text="Login" onClick={()=>setSubmit(true)} disabled={submit || username.length < 2 || password.length < 4}/>
    <Fetch submit={submit} 
          onError={(e)=>{setSubmit(false); props.setError(e)}} 
          onResult={(r)=>{
            window.localStorage.setItem("token", r.token); 
            window.localStorage.setItem("username", r.username); 
            window.localStorage.setItem("projection", r.projection);
            window.localStorage.setItem("rights", r.rights);
            if (socket)
              socket.emit('token', {token:r.token});
            setSubmit(false);
            props.setAccount(r.account); 
            props.setUsername(r.username); 
            props.setProjection(r.projection);
            props.setRights(r.rights);
            props.setView("map")}}
          api={getApi()+`/login?username=${ec(username)}&password=${ec(password)}`} />
    </div>;
} 

const Error = (props) => {
  return <div>
      <p>Information</p>
      <hr></hr>
      <div style={widgetStyle}>
      {props.text}
      </div>
      <hr></hr>
      <Button text="Back" onClick={props.onBack}/>
      </div>;
}

const TimeFilter = (props) => {
  const steps = props.steps ? props.steps : [ {text:"1h", value: 3600}, {text:"1d", value:24*3600}, {text:"∞", value:0} ];
  const width = props.width ? props.width : 32;
  const text = props.text ? props.text: "Filter";
  const position = Number.isInteger(props.position) ? props.position : 0;
  let get = props.get ? props.get : ()=>3600;
  let set = props.set ? props.set : (v)=>{console.log("No binding for set " + v)};
  const current = get();

  return <div style={{...widgetStyle, position:"fixed", bottom:"4vmin", right:position*33+"vw", width:width+"vw", height:"7vmin"}}>
    <div style={{height:"4vmin", fontSize:"3vmin", display:"flex"}}>
    { steps.map((s,i)=>{ 
      let color = current===s.value ? "#f44c":(current<s.value ? "#f444":"#ccc4");
      if (current === 0) {
        if (s.value === current)
          color = "#44fc";
        else
          color = "#8884";
      }
      else if (s.value === 0)
        color = "#44c4"
      return <div key={i} style={{height:"4vmin", width:width/steps.length+"vw",
                  color:current===s.value ? "#fff":"#444",
                  borderWidth:"1vmin", borderColor:"#ccc8", backgroundColor:color}} onClick={()=>set(s.value)}>{s.text}</div>}) }
    </div>
    <div style={{height:"2vmin", fontSize:"2vmin"}}>{text}</div>
  </div>
}


const calculateAlarmAgeSeconds = (device) => {
  if (!(device  && device.alarmTime))
    return -1;
  const now = new Date().getTime();
  let result = (now - new Date(device.alarmTime).getTime())/1000;
  return result;
}

const calculateTraceAgeSeconds = (device) => {
  if (!(device  && device.traceTime))
    return -1;
  const now = new Date().getTime();
  let result = (now - new Date(device.traceTime).getTime())/1000;
  return result;
}

const showAsTrace = (device) => {
  let ageS = calculateTraceAgeSeconds(device);
  if (ageS < 0 || ageS > getMaxTraceTimeS())
    return false
  return true;
}

const showAsAlarm = (device) => {
  let ageS = calculateAlarmAgeSeconds(device);
  if (ageS < 0 || ageS > getMaxAlarmTimeS())
    return false
  return true;
}

const calculateAlarmAgeMinutes = (device) => {
  let result = calculateAlarmAgeSeconds();
  if (result < 0)
    return -1;
  return result / 60;
}

const LastUpdateDisplay = ({last}) => {
  const [redraw, setRedraw] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(()=>setRedraw(()=>redraw+1), 5*1000);
    return ()=>clearInterval(timer);
  }, []);

  const nowS = new Date().getTime() / 1000;
  let ageS = last ? nowS-last.getTime()/1000 : "Connecting...";
  let color = "#000";
  if (typeof(ageS === "number")) {
    if (ageS > 90 ) {
      color = "#f00";
      ageS = "Offline for " + formatAge(ageS-30) + " (Retrying)";
    }
    else 
      ageS = "Online";
  }

  let debugInfo = "";
  if (isDebug()) {
    debugInfo = "[DEBUG] ";
  }

  return <div style={{class:"noselect", position:"fixed", bottom:"1.5vmin", left:"4vw", color, fontSize:"2.5vmin"}}>{debugInfo}{ageS}</div>
}

const AccuracySelector = (props) => {
  const {showAccuracy, setShowAccuracy} = props;
  return <div>
     <div style={{position:"fixed", 
      bottom:"8vmin", right:"7vmin", 
      borderRadius:"2vmin", 
      height:"4vmin", width:"4vmin", 
      background:showAccuracy ? deviceColor +"c" : "#cccc"}}
      onClick={()=>setShowAccuracy(!showAccuracy)}></div>
     <div style={{position:"fixed", 
      bottom:"9vmin", right:"8vmin", 
      borderRadius:"2vmin", 
      height:"2vmin", width:"2vmin", 
      background:showAccuracy ? deviceColor : "#888"}}
      onClick={()=>setShowAccuracy(!showAccuracy)}></div>
  </div>
}


const InfoBox = (props) => {
  const {series, isChecker, isAdmin, currentCheckpoint} = props;
  let count = 0;
  let lastUpdateString = "";
  let checkerString = "";
  let checkpointString = "";
  let minSignalAge_S = Number.MAX_SAFE_INTEGER;
  let currentCheckpointName = null;
  let [activateAll, setActivateAll] = React.useState(false);
  let [deactivateAll, setDeactivateAll] = React.useState(false);
  React.useEffect(()=>{
    let timer = null;
    if (activateAll)
      timer = setTimeout(() => setActivateAll(false), ACTIVATE_DEACTIVATE_TIMEOUT_S*1000);
    return () => timer && clearTimeout(timer);
  }, [activateAll]);
  React.useEffect(()=>{
    let timer = null;
    if (activateAll)
      timer = setTimeout(() => setDeactivateAll(false), ACTIVATE_DEACTIVATE_TIMEOUT_S*1000);
    return () => timer && clearTimeout(timer);
  }, [deactivateAll]);

  let traceCount = 0;
  if (Array.isArray(series)) {
    count = series.length;
    if (count == 0) {
      lastUpdateString = "";
    } else {
      const nowS = new Date().getTime()/1000;
      let checkedCount = 0;
      let uncheckedCount = 0;
      let checkpointCount = 0;
      for (let i  = 0; i < series.length; ++i) {
        const device = series[i];
        let offlineTimeS = device.timestamp ? nowS - new Date(device.timestamp).getTime()/1000 : Number.MAX_SAFE_INTEGER;
        if (offlineTimeS < minSignalAge_S)
          minSignalAge_S = offlineTimeS;
        if (!device.checkpoint) {
          if (device.checkinTime)
            checkedCount++;
          else
            uncheckedCount++;
        } else {
          checkpointCount++;
        }
        // console.log("currentCheckpoint", currentCheckpoint, device.id);
        if (currentCheckpoint === device.id) {
          currentCheckpointName = device.title;
        }
        if (showAsTrace(device))
          traceCount++;
      }
      if (isAdmin)
        lastUpdateString = "Last Signal: " + formatAge(minSignalAge_S+15);
      if (isChecker) {
        checkerString= checkedCount > 0 ? ("Checked: " + checkedCount + " Unchecked: " + uncheckedCount) : "";
        if (currentCheckpointName)
          checkpointString = "CURRENT CHECKPOINT: " + currentCheckpointName;
        else {
          checkpointString = checkedCount > 0 ? (checkpointCount+" CHECKPOINTS"):"";  
        }
      }
    }
  }
  return <div>
     <div style={{position:"fixed", 
      top:"2vmin", left:"10vmin", 
      textAlign:"left",
      ...smallTextStyle,
      color:"black",
      }}>
        {lastUpdateString}<br/>
        {isChecker ? <small style={{fontWeight:600}}>{checkerString}<br/></small> : null}
        {isChecker ? <small>{checkpointString}<br/></small> : null}
        {(!activateAll) ? <SureYesNoButton2 text="Track All Devices" info="It might take several minutes to activate tracing for all connected devices." onClick={()=>{setActivateAll(true); setDeactivateAll(false); }} className="track-all-devices-button" /> : null}        
        {(!deactivateAll) ? <SureYesNoButton text={`Deactivate Track Devices`} info="It might take several minutes to deactivate tracing for all connected devices." onClick={()=>{setDeactivateAll(true); setActivateAll(false)}}/> : null}
        {(activateAll) ? <small><b>TRACK ALL DEVICES PENDING</b><br></br></small> : null}
        {(deactivateAll) ? <small><b>DEACTIVATE TRACK ALL DEVICES PENDING</b><br></br></small> : null}
    </div>
    <div>
      {activateAll && series.map((i) => 
        !i.checkpoint ? 
        <Fetch key={"fetch-trace-"+i.id}
        submit={true} 
        onError={(e)=>{console.log("Some problem occurred when tracing device", i)}}
        onResult={(r)=>{console.log("Requested trace for device ", i.id)}}
        api={getApi()+`/downlink?token=${ec(window.localStorage.getItem('token'))}&device=${ec(i.id)}&port=2&data=9100000001`} /> : null
      )}
      {deactivateAll && series.map((i) => 
        !i.checkpoint ? 
        <Fetch key={"fetch-deactivate-trace-"+i.id}
        submit={true} 
        onError={(e)=>{console.log("Some problem occurred when stopping tracing device", i)}}
        onResult={(r)=>{console.log("Requested deactivate trace for device ", i.id)}}
        api={getApi()+`/downlink?token=${ec(window.localStorage.getItem('token'))}&device=${ec(i.id)}&port=2&data=9100000000`} /> : null
      )}
    </div>
  </div>
}

const TimeFilterBox = (props) => {
  const devices = props.devices;
  const position = props.position ? props.position : 0;
  const filtered = props.filtered;
  const predicate = props.predicate;
  const getAge = props.getAge;

  // Update this every few seconds
  const [redraw, setRedraw] = React.useState(0);
  const [expand, setExpand] = React.useState(false);

  React.useEffect(() => {
    const timer = setInterval(()=>setRedraw(()=>redraw+1), 5*1000);
    return ()=>clearInterval(timer);
  }, []);

  let alarms = [];
  devices.map(d=>{if (predicate(d)) alarms.push(d); });
  
  if (!alarms.length)
    return <div></div>

  const nowS = new Date().getTime()/1000;

  return <div style={{...buttonStyle, textAlign:"left", background: "#eeec", position:"fixed", right:position*33+"vw", 
                      bottom:"12vmin", width:"29vw", fontSize:"2.5vmin", maxHeight:"30vh", borderWidth:".1vmin", borderColor:"blue", borderStyle:"solid" }}>
    <div style={{overflowY:"auto",maxHeight:"25vh", }}>
    { expand && alarms.map(device=>{ 
      const age = (getAge(device))*60;
      return <div key={device.id} style={{fontSize:"1.8vmin", fontWeight:600, display:"flex", background:props.get()===device.id ? selectColor:deviceColor, margin:"0.5vmin", padding:"0.5vmin"}} onClick={()=>props.set(device.id)}>
        <div style={{height:"1.3vmin", width:"1.3vmin", margin:"0.5vmin", borderRadius:"1vmin", background:calculateAlarmAgeMinutes(device) >= 0 ? "#f00" : "#ccc"}} >
      </div>{(device.title && device.title.length > 0) ? device.title : device.text} | {formatAge(age)}</div>
      })}
    </div>
    { expand && <hr/>}
    <div style={{textAlign:"center", color:"red"}} onClick={()=>setExpand(!expand)}>{alarms.length} {props.text}</div>
    </div> 
}

const Trace = (props) => {
  let {trace, zoom, setZoom, close, devices, selected, projection, isAdmin, isChecker} = props;
  const [selectedSolutionId, setSelectedSolutionId] = React.useState(null);
  const [positionLimitS, setPositionLimitS] = React.useState(0);
  const showAccuracyStored = window.localStorage.getItem("showAccuracy");
  const [showAccuracy, setShowAccuracy] = React.useState(showAccuracyStored !== null ? showAccuracyStored==="true" : true);
  const [editedTitle, setEditedTitle] = React.useState(null);
  const [commitTitle, setCommitTitle] = React.useState(null);
  const [commitCheckpoint, setCommitCheckpoint] = React.useState(null);
  const [currentCheckpoint, _setCurrentCheckpoint] = React.useState(window.localStorage.getItem('current-checkpoint'));
 
  const setCurrentCheckpoint = (id) => {
    if (!id) {
      window.localStorage.removeItem('current-checkpoint');
      console.log("clear current checkpoint");
    }
    else {
      window.localStorage.setItem("current-checkpoint", id);
      console.log("store current checkpoint");
    }
    _setCurrentCheckpoint(id);
  }

  let mapSeries = [];
  let firstSolutionTime = null;
  let deviceTitle = "";
  let deviceText = "";

  const [redraw, setRedraw] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(()=>{setRedraw(()=>redraw+1)}, 30*1000);
    return ()=>clearInterval(timer);
  }, []);

  // Automatically close trace view after 2 minutes
  React.useEffect(() => {
    const timer = setInterval(()=>close(), 120*1000);
    return ()=>clearInterval(timer);
  }, []);

  // determine selected name and device name
  let checkpoint = false;
  let alarm = false;
  let checked = false;
  for (let i = 0; i < devices.length; ++i) {
    if (devices[i].id == selected) {
      deviceTitle = devices[i].title;
      deviceText = devices[i].text;
      checkpoint |= devices[i].checkpoint ? true : false;
      alarm |= devices[i].alarmTime ? true : false;
      checked = devices[i].checkinTime ? true : false;
      break;
    }
  }


  // Filter out a time series
  const nowS = new Date().getTime()/1000;
  let maxPositionAgeS = positionLimitS ? positionLimitS : 3600*24;
  Array.isArray(trace) && trace.map((solution, i) => {
    const time = new Date(solution.captureTime);
    const ageS = nowS - time.getTime()/1000;
    if (positionLimitS && ageS > positionLimitS)
      return; // Filter out
    if (solution.lat && solution.lng) {
      if (firstSolutionTime === null)
        firstSolutionTime = time;
      mapSeries.unshift({
        title: time.toLocaleString(),
        latlng: [solution.lat, solution.lng],
        lat: solution.lat,
        lng: solution.lng,
        floor: solution.floor,
        timestamp: solution.captureTime,
        addedTimestamp: solution.addedAt,
        positionTime: solution.captureTime,
        accuracy:solution.accuracy,
        status:solution.error,
        id:solution.id,
      })
    }
  })

  const hPosition = {left:"1vmin", display:"flex", } ;
  const closeButtonPosition  = {...hPosition, position:"fixed", top:'1vh', bottom:'20vh', background:"#44c4", borderRadius:"1vmin" };

  let editCommitTitleData = {}
  editCommitTitleData[selected] = commitTitle ? commitTitle : deviceTitle;

  return <div>
      <div style={{position:"fixed", top:"0", left:"0", width:"100vw", height:"100vh"}}>
      {mapSeries.length > 0 && (projection === "timespace" || showAccuracy) && <TimeSpace width="100vw" height="100vh" top="0" series={mapSeries} center={mapSeries[mapSeries.length-1].latlng} 
                  redraw={redraw} maxAge={maxPositionAgeS} selected={selectedSolutionId}
                    zoom={zoom} setZoom={setZoom} markerFactory={produceSolutionMarkers} showAccuracy={showAccuracy}/> }
      {mapSeries.length > 0 && (projection === "sideways" && !showAccuracy) && <SideWays width="100vw" height="100vh" top="0" series={mapSeries} center={mapSeries[mapSeries.length-1].latlng} 
                  redraw={redraw} maxAge={maxPositionAgeS} selected={selectedSolutionId} isChecker={isChecker} isAdmin={isAdmin}
                  setCurrentCheckpoint={setCurrentCheckpoint} currentCheckpoint={currentCheckpoint}
                    zoom={zoom} setZoom={setZoom} markerFactory={produceSolutionMarkers} showAccuracy={showAccuracy}/> }
      {(mapSeries.length === 0) ? 
          <div style={{...smallTextStyle, position:"fixed", top:"40vh", left:"40vw", }}>No solved positions matching filter.</div> : null}

    </div>

    <div style={{display:"flex", flexDirection:"row", position:"fixed",top:"1.8vh",left:isAdmin?"9vw":"10vw", }}>
      <div style={{fontSize:"3vmin"}}>
        { (isAdmin && !commitTitle) ? <Input style={{paddingLeft:0}} 
                        value={editedTitle ? editedTitle : deviceTitle}
                        onChange={v=>setEditedTitle(v)}/> 
                  : deviceTitle }
      </div>
      {(isAdmin && editedTitle && editedTitle !== deviceTitle && !commitTitle) ? 
        <SureYesNoButton key="sb1" style={{top:"1vmin"}} text="Rename" onClick={()=>{setCommitTitle(editedTitle); setEditedTitle(null)}} onNo={()=>setEditedTitle(null)}/> : null}
      {(isAdmin && !editedTitle) ? 
        <SureYesNoButton key="sb2" style={{top:"1vmin"}} text={checkpoint ? "Unmake Checkpoint" : "Make Checkpoint"} onClick={()=>{setCommitCheckpoint(checkpoint ? 0 : 1)}}/> : null}
    </div>
    <div style={{position:"fixed",top:"5.5vh",left:"10vw", fontSize:"2vmin"}}>{deviceText} 
        {checked ? " CHECKED" : null}
        {alarm ? " ALARM":null} 
        {checkpoint ? " CHECKPOINT" : null}</div>
    <List key="trace" 
        items={trace}
        text="List"
        left={false}
        headers={["Capture Time", "Solver", "Deck", "Status"]}
        format={(solution)=>{return {captureTime:new Date(solution.captureTime).toLocaleString(),
                                  solver:solution.solver,
                                  floor:solution.floor,
                                  status:solution.error}}}
        select={(solution)=>setSelectedSolutionId(solution ? solution.id : null)}
        sortReversed={true}
        isSelected={solution=>selectedSolutionId===solution.id}
      />

    <TimeFilter key="t1" position={1} text="Max Position Age" set={setPositionLimitS} get={()=>positionLimitS}/>
    <AccuracySelector key="as" setShowAccuracy={(v)=>{setShowAccuracy(v); window.localStorage.setItem("showAccuracy", v)}} showAccuracy={showAccuracy} />
    <OpenCloseButton open={true} setOpen={close} position={closeButtonPosition}/>
    {commitTitle ? <Fetch key={"submit-device-title"} 
          submit={true} 
          onError={(e)=>{setCommitTitle(null);}}
          onResult={(r)=>{
            setCommitTitle(null);
          }}
          api={getApi()+`/submitTitleEdits?token=${ec(window.localStorage.getItem('token'))}&edits=${ec(JSON.stringify(editCommitTitleData))}`} />
     : null}
    {commitCheckpoint !== null ? <Fetch key={"submit-checkpoint"} 
          submit={true} 
          onError={(e)=>{setCommitCheckpoint(null);}}
          onResult={(r)=>{
            setCommitCheckpoint(null);
          }}
          api={getApi()+`/submitCheckpoint?token=${ec(window.localStorage.getItem('token'))}&device=${ec(selected)}&checkpoint=${ec(commitCheckpoint)}`} />
     : null}
  </div>
}


const Map = (props) => {
  let {devices, log, tracing, lastUpdateTime, accountname, username, zoom, setZoom, 
      pendingAck, setPendingAck, pendingDis, setPendingDis, selected, setSelected, 
      center, projection, isAdmin, isChecker, isFilterer, showFilters, users, setPendingUsers, pendingUsers} = props;

  const queryParameters = new URLSearchParams(window.location.search)
  let defaultAlarmLimitS = parseInt(queryParameters.get("alarm"));
  let defaultPositionLimitS = parseInt(queryParameters.get("position"));
  let defaultSignalLimitS = parseInt(queryParameters.get("signal"));
  
  const [offlineLimitS, setOfflineLimitS] = React.useState(defaultSignalLimitS);
  const [positionLimitS, setPositionLimitS] = React.useState(defaultPositionLimitS);
  const [alarmLimitS, setAlarmLimitS] = React.useState(defaultAlarmLimitS);
  const [search, setSearch] = React.useState("");
  const [deviceTitleEdits, setDeviceTitleEdits] = React.useState({}); // Map device ID to new title
  const [submittedTitleEdits, setSubmittedTitleEdits] = React.useState(null);
  const [userDataEdits, setUserDataEdits] = React.useState({}); // Map of changed user data
  const [submittedUserDataEdits, setSubmittedUserDataEdits] = React.useState(null); // Map of changed user data
  const [error, setError] = React.useState(null);
  const [addUserSubmit, setAddUserSubmit] = React.useState(null);
  const [addDeviceSubmit, setAddDeviceSubmit] = React.useState(null);
  const [deleteUserSubmit, setDeleteUserSubmit] = React.useState(null);
  const [deleteDeviceSubmit, setDeleteDeviceSubmit] = React.useState(null);
  const [currentCheckpoint, _setCurrentCheckpoint] = React.useState(window.localStorage.getItem('current-checkpoint')); // ID of current checkpoint

  /* Paste here should you want to test with objects from another server / time
    devices = JSON.parse(``); 
    */

  const toggleSelected = (id) => {
    if (selected === id)
      setSelected("");
    else 
      setSelected(id);
  }

  const setCurrentCheckpoint = (id) => {
    if (!id) {
      window.localStorage.removeItem('current-checkpoint');
      console.log("clear current checkpoint");
    }
    else {
      window.localStorage.setItem("current-checkpoint", id);
      console.log("store current checkpoint " + id);
    }
    _setCurrentCheckpoint(id);
  }

  const [redraw, setRedraw] = React.useState(0);
  React.useEffect(() => {
    const timer = setInterval(()=>{setRedraw(()=>redraw+1)}, 30*1000);
    return ()=>clearInterval(timer);
  }, []);

  let series = [];
  const nowS = new Date().getTime()/1000;
  let haveSelectedId = selected && selected.length > 0;
  console.log("Search: " + search);
  const searchLc = search.toLowerCase();

  let idToTitleMap = {};
  devices.map(device => {
    if (device.id)
      idToTitleMap[device.id] = device.title;
  });
  const getTitleById  = (id) => {
    if (idToTitleMap.hasOwnProperty(id))
      return idToTitleMap[id];
    return "";
  }

  devices.map(device => {
    if (Array.isArray(device.latlng) && device.latlng.length >= 2 && Number.isFinite(device.latlng[0]) && Number.isFinite(device.latlng[1])) {
      device.lat = device.latlng[0];
      device.lng = device.latlng[1];

      // Add on acknowledge alarm?
      if (device.alarmTime && device.canAcknowledge && !device.alarmAcknowledge && !pendingAck) {
        device.onAck = () => { setPendingAck(device); };
      }
      // Add on disable alarm?
      if (device.alarmTime && !pendingDis) {
        device.onDis = () => { setPendingDis(device); };
      }

      device.onSelect = () => setSelected(device.id);

      let offlineTimeS = device.timestamp ? nowS - new Date(device.timestamp).getTime()/1000 : Number.MAX_SAFE_INTEGER;
      let positionTimeS = device.positionTime ? nowS - new Date(device.positionTime).getTime()/1000 : Number.MAX_SAFE_INTEGER;
      let alarmTimeS = calculateAlarmAgeSeconds(device);
      if (alarmTimeS < 0)
        alarmTimeS = Number.MAX_SAFE_INTEGER;

      if (offlineLimitS && offlineLimitS < offlineTimeS) {
        return; // Skip this device
      }

      if (positionLimitS && positionLimitS < positionTimeS) {
        return; // Skip this device
      }

      if (alarmLimitS && alarmLimitS < alarmTimeS) {
        return; // Skip this device
      }

      // Filter out units which have too long alarm time and are not checked and are not checkpoints
      if (!(isAdmin || isFilterer || isChecker)) {
        if (alarmTimeS > getMaxAlarmTimeS() && !device.checkpoint && !device.checkinTime && !device.traceTime) {
          console.log("Skipping device " + device.title + " due to old alarm age, not checked and not checkpoint");
          return;
        }
      }

      if (search.length > 0) {
        let match = false;
        match |= device.text && device.text.toLowerCase().includes(searchLc);
        match |= device.title && device.title.toLowerCase().includes(searchLc);
        match |= device.id && device.id.toLowerCase().includes(searchLc);
        if (!match)
          return;
      }
      
      series.push(device);
    } else {
    }
    return;
  });

  const unitCount = devices.length;
  const filterCount = unitCount - series.length;
  let mapSeries = series;
  if (haveSelectedId) {
    series.map((device) => {
      if (device.id === selected)
        mapSeries = [device];
    })
  }

  const onEditDeviceTitle = (device, value) => {
    console.log("Edit Title:", device, value);
    let newEdits = JSON.parse(JSON.stringify(deviceTitleEdits));
    newEdits[device.id] = value;
    setDeviceTitleEdits(newEdits);
  }

  const onEditUserDataField = (user, field, value) => {
    let newEdits = JSON.parse(JSON.stringify(userDataEdits));
    let deviceData = {}; 
    if (newEdits.hasOwnProperty(user.id))
      deviceData = newEdits[user.id];
    deviceData[field] = value;
    newEdits[user.id] = deviceData;
    setUserDataEdits(newEdits);
  }

  const onEditUserName = (user, value) => {
    console.log("Edit Username:", user, value);
    onEditUserDataField(user, "username", value);
  }
  const onEditUserPassword = (user, value) => {
    console.log("Edit User password:", user, value);
    onEditUserDataField(user, "password", value);
  }
  const onEditUserRights = (user, value) => {
    console.log("Edit User rights:", user, value);
    onEditUserDataField(user, "rights", value);
  }
  const onDeleteUser = (id) => {
    setDeleteUserSubmit(id);
  }
  const onDeleteDevice = (id) => {
    setDeleteDeviceSubmit(id);
  }
  const onAddUser = (data) => {
    console.log("Adduser:", data);
    if (data.name.length < 2) {
      setError("Too short username");
      return;
    }
    if (data.password.length < 4) {
      setError("Too short password");
      return;
    }
    setAddUserSubmit({username:data.name, password:data.password, rights:data.rights});
  }
  const onAddDevice = (data) => {
    console.log("onAddDevice, data", data);
    const serial = data.serial;
    const title = data.title;
    let intSerial = Number.parseInt(serial);
    if (intSerial.toString(10) !== serial || intSerial < 1000000) {
      setError("The serial number should be a number corresponding to a device serial number");
      return;
    }
    console.log(intSerial, title);
    setAddDeviceSubmit(data);
  }

  const onSubmitTitleEdits = () => {
    console.log("deviceTitleEdits:", deviceTitleEdits);
    setSubmittedTitleEdits(deviceTitleEdits);
    setDeviceTitleEdits({});
  }

  const onSubmitUserDataEdits = () => {
    console.log("userDataEdits:", userDataEdits);
    setSubmittedUserDataEdits(userDataEdits);
    setUserDataEdits({});
  }

  return <div>
      <div style={{position:"fixed", top:"0", left:"0", width:"100vw", height:"100vh"}}>
        {series.length > 0 && projection === "timespace" &&
          <TimeSpace width="100vw" height="100vh" top="0" series={mapSeries} center={center ? center : series[series.length-1].latlng} redraw={redraw} maxAge={positionLimitS ? positionLimitS:14*24*3600} selected={selected}
                    zoom={zoom} setZoom={setZoom} markerFactory={produceDeviceMarkers} waitForPos={props.waitForPos} showAccuracy={false}/> }
        {projection === "sideways" &&
          <SideWays 
                  width="100vw" height="100vh" top="0" series={mapSeries}
                  tracing={tracing} isChecker={isChecker} isAdmin={isAdmin}
                  setCurrentCheckpoint={setCurrentCheckpoint} currentCheckpoint={currentCheckpoint}
                  redraw={redraw} maxAge={positionLimitS ? positionLimitS:14*24*3600} selected={selected} setSelected={setSelected}
                    zoom={zoom} setZoom={setZoom} markerFactory={produceDeviceMarkers} waitForPos={props.waitForPos} showAccuracy={false}/> }
        {(projection === "timespace" && series.length === 0) ? 
          <div style={{...smallTextStyle, position:"fixed", top:"40vh", left:"40vw", }}>{unitCount} available - filtered out {filterCount}.</div> : null}
      </div>
      <div style={{position:"absolute", top:"2vmin", right:"10vmin", fontSize:"2vmin", color:"#444", textAlign:"right"}}>
        <div>
          {username}<br/>
          <small>
          {accountname+" "} {isAdmin ? "ADMIN ":""} {isChecker ? "CHECKER":""}
          </small>
        </div>
        <SureYesNoButton onClick={props.onLogout} text="Log out"/>
      </div>
      {<InfoBox key={currentCheckpoint} series={series} isChecker={isChecker} isAdmin={isAdmin} currentCheckpoint={currentCheckpoint}/>}
      {showFilters && <SearchBox search={search} setSearch={setSearch} spaceForFilters={showFilters}/>}
      {isFinite(defaultAlarmLimitS) || (showFilters && <TimeFilter key="t0" position={0} text="Max Alarm Age" set={setAlarmLimitS} get={()=>alarmLimitS}/>) }
      {isFinite(defaultPositionLimitS) || (showFilters && <TimeFilter key="t1" position={1} text="Max Position Age" set={setPositionLimitS} get={()=>positionLimitS}/>) }
      {isFinite(defaultSignalLimitS) || (showFilters && <TimeFilter key="t2" position={2} text="Max Signal Age" set={setOfflineLimitS} get={()=>offlineLimitS}/>) }
      {isFinite(defaultAlarmLimitS) || (showFilters && 
        <TimeFilterBox   key="a0" position={0} filtered={series} devices={devices} set={toggleSelected} get={()=>selected} 
                  predicate={d=> { const at = calculateAlarmAgeMinutes(d); return at > 0 && (alarmLimitS === 0 || at <= alarmLimitS/60);} } 
                  getAge={d=>calculateAlarmAgeMinutes(d)} text="Alarms"/>) }
    {isFinite(defaultPositionLimitS) || (showFilters && 
      <TimeFilterBox   key="a1" position={1} filtered={series} devices={devices} set={toggleSelected} get={()=>selected} 
                  predicate={d=>positionLimitS && d.positionTime && (nowS - new Date(d.positionTime)/1000 > positionLimitS)} 
                  getAge={d=>(new Date().getTime() - new Date(d.positionTime).getTime())/60/1000} text="Unpositioned"/>) }
    {isFinite(defaultSignalLimitS) || (showFilters && 
      <TimeFilterBox   key="a2" position={2} filtered={series} devices={devices} set={toggleSelected} get={()=>selected} 
                  predicate={d=>offlineLimitS && d.timestamp && (nowS - new Date(d.timestamp)/1000 > offlineLimitS)} 
                  getAge={d=>(new Date().getTime() - new Date(d.timestamp).getTime())/60/1000} text="Offline"/>) }
      {isAdmin && <List key="log" 
          icon='☰'
          items={log}
          left={false}
          text="Log"
          headers={["Log Timestamp", "Actor", "Event"]}
          format={(item)=>{return {timestamp:new Date(item.timestamp).toLocaleString(), user:item.username, text:item.text}}}
          select={(item)=>setSelected(item ? item.device : "")}
          isSelected={item=>selected===item.device}
          sortReversed={true}
          />}
      {isAdmin && <List key="devices" 
          icon='☷'
          items={series}
          text="Devices"
          left={true}
          headers={["Title", "Device", "Signal Age", "Position Age", "Alarm Age"]}
          format={(device)=>{let d = {title:device.title, 
                                    device: device.text + (isBatteryLow(device) ? (" 🪫 (" + (Math.round(device.output.volts * 10) / 10) + "V)") : ""),
                                    timestamp: device.timestamp ? formatDateAsAge(new Date(device.timestamp)) : "",
                                    positioned:device.positionTime ? formatDateAsAge(new Date(device.positionTime)) : "",
                                    alarm:device.alarmTime ? formatDateAsAge(new Date(device.alarmTime)) : "",
                                  }
                              return d;
                                }}
          select={(device)=>setSelected(device? device.id : "")}
          editableFields={isAdmin ? {title:onEditDeviceTitle} : {}}
          editedFields={deviceTitleEdits}
          onSubmitEdits={isAdmin ? ()=>onSubmitTitleEdits() : null}
          onCancelEdits={isAdmin ? ()=>setDeviceTitleEdits({}) : null}
          onDelete={isAdmin ? (id)=>onDeleteDevice(id) : null}
          onAdd={isAdmin ? (data)=>onAddDevice(data) : null}
          addTemplate={[{id:"serial", name:"Serial Number", value:""}, {id:"title", name: "Title", value: "NewDevice"}]}
          isSelected={device=>selected===device.id}
          />}
      {isChecker && <List key="check" 
          icon='✔'
          items={series}
          text="Checkins"
          left={true}
          low={isAdmin /* If so, the devices are above */}
          headers={["Title", "Check Age", "Checkpoint"]}
          format={(device)=>{let d = {
                                    title:device.title, 
                                    check:(device.checkpoint ? "CHECKPOINT " : "") + (device.checkinTime ? formatDateAsAge(new Date(device.checkinTime)) : ""),
                                    checkpoint: getTitleById(device.checkinCheckpoint)
                                  }
                              return d;
                                }}
          select={(device)=>setSelected(device? device.id : "")}
          addTemplate={[{id:"serial", name:"Serial Number", value:""}, {id:"title", name: "Title", value: "NewDevice"}]}
          isSelected={device=>selected===device.id}
          />}
      {isAdmin && <List key="users" 
          icon='⛑'
          items={users}
          text="Users"
          left={false}
          low={true}
          headers={["Username", "Rights", "Password"]}
          format={(user)=>{return { name:user.username, 
                                    rights:user.rights,
                                    password:"",
                                  }}}
          select={(device)=>setSelected(device? device.id : "")}
          editableFields={isAdmin ? {name:onEditUserName, rights:onEditUserRights, password:onEditUserPassword} : {}}
          editedFields={userDataEdits}
          onSubmitEdits={()=>onSubmitUserDataEdits()}
          onCancelEdits={()=>setUserDataEdits({})}
          onDelete={(id)=>onDeleteUser(id)}
          onAdd={(data)=>onAddUser(data)}
          addTemplate={[{id:"name", name:"Login", value:"NewUser"}, 
                        {id:"rights", name:"Rights (A=Admin, C=CheckIn, F=Filter)", value:"ACF"}, 
                        {id:"password", name:"Password", value:""}]}
          isSelected={device=>selected===device.id}
          />}
      <LastUpdateDisplay key="lud" last={lastUpdateTime} />
      {error && <div onClick={()=>setError(null)} style={{...smallTextStyle, position:"fixed", bottom:"12vh", left:"4vmin", color:"red" }}>{error}</div>}
      {isAdmin && submittedTitleEdits && <Fetch key={"submit-devices"} 
          submit={true} 
          onError={(e)=>{setError(e.error); setSubmittedTitleEdits(null);}}
          onResult={(r)=>{
            setError(r.message);
            setSubmittedTitleEdits(null);
          }}
          api={getApi()+`/submitTitleEdits?token=${ec(window.localStorage.getItem('token'))}&edits=${ec(JSON.stringify(submittedTitleEdits))}`} /> }
      {isAdmin && submittedUserDataEdits && <Fetch key={"submit-userDataEdits"} 
          submit={true} 
          onError={(e)=>{setError(e.error); setSubmittedUserDataEdits(null);}}
          onResult={(r)=>{
            setError(r.message);
            setSubmittedUserDataEdits(null);
          }}
          api={getApi()+`/submitUserEdits?token=${ec(window.localStorage.getItem('token'))}&edits=${ec(JSON.stringify(submittedUserDataEdits))}`} /> }
      {isAdmin && addUserSubmit && <Fetch key={"submit-new-user"} 
          submit={true} 
          onError={(e)=>{setError(e.error); setAddUserSubmit(null);}}
          onResult={(r)=>{
            setError(r.message);
            setAddUserSubmit(null);
          }}
          api={getApi()+`/submitAddUser?token=${ec(window.localStorage.getItem('token'))}&data=${ec(JSON.stringify(addUserSubmit))}`} /> }
      {isAdmin && addDeviceSubmit && <Fetch key={"submit-new-device"} 
          submit={true} 
          onError={(e)=>{setError(e.error); setAddDeviceSubmit(null);}}
          onResult={(r)=>{
            setError(r.message);
            setAddDeviceSubmit(null);
          }}
          api={getApi()+`/submitAddDevice?token=${ec(window.localStorage.getItem('token'))}&data=${ec(JSON.stringify(addDeviceSubmit))}`} /> }
      {isAdmin && deleteUserSubmit && <Fetch key={"submit-delete-user"} 
          submit={true} 
          onError={(e)=>{setError(e.error); setDeleteUserSubmit(null);}}
          onResult={(r)=>{
            setError(r.message);
            setDeleteUserSubmit(null);
          }}
          api={getApi()+`/submitDeleteUser?token=${ec(window.localStorage.getItem('token'))}&id=${ec(deleteUserSubmit)}`} /> }
      {isAdmin && deleteDeviceSubmit && <Fetch key={"submit-delete-device"} 
          submit={true} 
          onError={(e)=>{setError(e.error); setDeleteDeviceSubmit(null);}}
          onResult={(r)=>{
            console.log("Hello!");
            setError(r.message);
            setDeleteDeviceSubmit(null);
          }}
          api={getApi()+`/submitDeleteDevice?token=${ec(window.localStorage.getItem('token'))}&id=${ec(deleteDeviceSubmit)}`} /> }
    </div>;
}

const OpenCloseButton = (props) => {
  const {position, open, setOpen, text, left, icon} = props;
  //     <div style={{rotate:left?(open?"-45deg":"135deg"):(open?"45deg":"-135deg"), margin:"2px", padding:"1.4vmin", borderColor:"#888", borderBottomWidth:"0px", borderLeftWidth:left?"4px":"0px", borderRightWidth:left?"0px":"4px", borderTopWidth:"4px", borderStyle:"solid"}}></div>
  return <div style={{...buttonStyle,  ...props.position, minWidth:"4vmin", width:"4vmin", height:"4vmin", background:"#fff8", borderStyle:"solid", textAlign:"center", 
                      borderColor:"#eee4", fontWeight:"800", fontSize:"2.8vmin", textAlign:"center" }} onClick={()=>setOpen(!open)} >
    {open ? <div style={{minWidth:"4vmin", }}>⊝<br/><div style={{fontSize:"1.1vmin"}}>Close</div></div> 
          : <div style={{minWidth:"4vmin", }}>{icon ? icon : '⊞'}<br/><div style={{fontSize:"1.1vmin"}}>{text}</div></div>}
    </div>
}

const List = (props) => {
  const {left, low, items, format, headers, select, isSelected, text, 
          editableFields, editedFields, onClose, onSubmitEdits, onCancelEdits, 
          icon, onAdd, onDelete, addTemplate} = props;
  const [open, setOpen2] = React.useState(props.open ? true : false);
  const [scale, setScale] = React.useState(100);
  const [sortBy, setSortBy] = React.useState(0);
  const [sortReversed, setSortReversed] = React.useState(props.sortReversed ? true : false);
  const [adding, setAdding] = React.useState(null);
  const [addEdits, setAddEdits] = React.useState({});
  const [deleteConfirm, setDeleteConfirm] = React.useState(null);
  const [scroll, _setScroll] = React.useState(0);
  const [touchY, setTouchY] = React.useState(0);

  const setOpen = (value) => { if (value === true) { setScale(100);setScroll(0);} else {if (onClose) onClose()}; setOpen2(value); }

  const top = low ? '8vh':'1vh';
  const topadd = low ? '16vh' : '9vh';
  const hPosition = left ? {left:"1vmin", display:"flex", } : {right:"1vmin", display:"flex", flexDirection:"row-reverse"};
  const position  = {...hPosition, position:"fixed", top, bottom:'20vh', background:"#44c4", borderRadius:"1vmin" };

  const itemHeightVh = 3.6;
  const heightVh = 70;
  const itemCount = (heightVh-10)/itemHeightVh;

  const setScroll = (y) => {
    if (y < 0) {
      _setScroll(0);
    }
    else if (items) {
      if (y > items.length-itemCount) {
        _setScroll(items.length-itemCount);
      } else {
        _setScroll(y);
      }
    } else {
          _setScroll(0);
    }
  }


  if (!open) {
    return <OpenCloseButton position={position} open={open} setOpen={setOpen} text={text} left={left} icon={icon}/>
  }

  let deleteConfirmButton = null;
  if (deleteConfirm) {
    deleteConfirmButton =<SureYesNoButton open={true} noText={true} key="ttt" text="Delete" onClick={()=>{onDelete(deleteConfirm); setDeleteConfirm(null)}} onNo={()=>setDeleteConfirm(null)} />;
  }

  const formatItem = (item, formatted, ix) => {
    const itemWidth = (99/Object.keys(formatted).length) + "%";
    return <div key={ix}
                style={{background:isSelected(item)?"#bbf":"#fff", height: itemHeightVh+"vh", width:"98%", margin:1, fontSize:"1.8vmin", display:"flex", flexDirection:"row", opacity:"70%"}}
                onClick={(e)=>{e.stopPropagation(); isSelected(item)?select(null):select(item)}}>
      {Object.keys(formatted).map((k,i)=><div key={i} style={{width:itemWidth}}>{
        editableFields && editableFields.hasOwnProperty(k) ? 
          <input style={{fontSize:"1.8vmin"}} onClick={(e)=>{e.stopPropagation()}} 
                 onChange={(e)=>editableFields[k](item,e.target.value)} 
                 value={editedFields.hasOwnProperty(item.id) ? (typeof(editedFields[item.id])==='object' ? editedFields[item.id][k]: editedFields[item.id]) : formatted[k]}/> : formatted[k]}</div>)}
        {onDelete ? <div onClick={(e)=>{e.stopPropagation();setDeleteConfirm(item.id)}} key="dd" style={{fontWeight:600,width:"3vmin",}}>⊝</div> : null}
      </div>
  }

  const formatHeader = (headers) => {
    const itemWidth = (99/headers.length) + "%";
    return <div key={"head"}
                style={{background:"white", height: "2vh", width:"98%", margin:1, fontSize:"1.8vmin", fontWeigth:600, display:"flex", flexDirection:"row", borderBottomStyle:"solid", borderBottomColor:"#ccc8"}}
                onClick={(e)=>{e.stopPropagation(); select(null);}}>
      {headers.map((k,i)=><div key={i} style={{width:itemWidth, fontWeight:sortBy===i ? 600 : 300}}
                                onClick={(e)=>{e.stopPropagation(); if (sortBy !== i) setSortBy(i); else setSortReversed(!sortReversed);}}>{k} {sortBy!==i ? "" : (sortReversed ?"▼":"▲")}</div>)}
      {onDelete ? <div key="del" style={{width:"3vmin"}}/> : null}
      </div>
  }

  const generateDefault = ()=>{
    if (!addTemplate)
      return {};
    let obj = {};
    for (let i = 0; i < addTemplate.length; ++i) {
      obj[addTemplate[i].id] = addTemplate[i].value;
    }
    return obj;
  };

  if (adding) {
    let items=[];
    if (addTemplate)
      items = addTemplate;
    else { // Generate a template dynamically
      for (let i = 0; i < headers.length;  ++i) {
        let obj = {id:headers[i], name:headers[i], value:""}
        items.push(obj);
      }
    }
    const formatItem = (item) => {return {name:item.name, value:item.value}};
    const onEditValue= (obj, value) => {
      console.log("onEditValue", obj.id, value);
      console.log("previous:", addEdits);
      let copy = JSON.parse(JSON.stringify(addEdits));
      copy[obj.id] = value;
      setAddEdits(copy);
      console.log("next:", copy);
    }
    return <List key="add-list" left={left} low={low} name={"Add"} open={true} 
                 text={text}
                 icon="+"
                 items={items} format={formatItem} isSelected={()=>{}} select={()=>{}}
                 editableFields={{value:onEditValue}} editedFields={addEdits}
                 onSubmitEdits={()=>{onAdd(addEdits); setAddEdits({}); setAdding(null)}}
                 onCancelEdits={()=>setAddEdits({})}
                 onClose={()=>setAdding(null)}></List>
  }

  let formatted = [];
  let formattedToItem = {};
  if (Array.isArray(items)) {
    items.map(item => {
      const f = format(item);
      formattedToItem[JSON.stringify(f)] = item;
      formatted.push(f);
    });
  }

  const keys = formatted.length > 0 ? Object.keys(formatted[0]) : [];
  formatted.sort((a,b) => {
  // Todo: Invent age formatting
  let result = strangeCompare(a[keys[sortBy]], b[keys[sortBy]]); if (sortReversed) result = -result; return result;});

  const visibleVh = items.length > itemCount ? ((heightVh-3)*itemCount/items.length) : (heightVh-3);
  const scrollVh = (heightVh-6)*scroll/items.length;

  const scrollTopMargin = 3+scrollVh;
  const scrollHeight = visibleVh;

  return <div style={{...position, width:'85vw', minHeight:heightVh+"vh", maxHeight:heightVh+"vh", touchAction:"none"}}
              onWheel={(e)=>{setScroll(scroll+e.deltaY/20)}}
              onTouchStart={(e)=>{setTouchY(e.touches[e.touches.length-1].screenY)}}
              onTouchMove={(e)=>{console.log("tl: "+e.touches.length); setScroll(scroll-(e.touches[e.touches.length-1].screenY-touchY)/10);setTouchY(e.touches[e.touches.length-1].screenY)} }
              onClick={()=>setOpen(false)}>
    <OpenCloseButton position={position} open={open} setOpen={setOpen} text={text} left={left} />
      {onAdd ? <div onClick={e=>{e.stopPropagation(); setAdding(true); setAddEdits(generateDefault());}}style={{...position, top:topadd, padding:"2vw", height:"3vmin", alignContent:"center", fontSize:"3vmin", background:"#0000", minHeight:"20vh" }}>+</div> : null}
      <div style={{width:"9vmin", background:"#ccc3"}}/>
      <div style={{scale:scale+"%", margin:"1%", width:"83vw", minHeight:(heightVh-2)+"vh", maxHeight:(heightVh-2)+"vh", background:"#eef8", textAlign:"left", pointerEvents:"all"}}>
        {editedFields && Object.keys(editedFields).length > 0 && <div>
          <SmallButton text="Submit Edits" onClick={()=>onSubmitEdits()}/>
          <SmallButton text="Cancel" onClick={()=>onCancelEdits()}/></div>}
        {headers && formatHeader(headers)}
        {formatted.map((formatted, ix)=>(ix>=scroll&&ix<scroll+itemCount) ? formatItem(formattedToItem[JSON.stringify(formatted)], formatted, ix) : null)}
      </div>
      {formatted.length>itemCount ? <div style={{heigth:"98%", borderStyle:"solid", borderColor:"#fff8", background:"#55c4", width:"5px", marginRight:"0.2vmin", marginTop:scrollTopMargin+"vh", height:scrollHeight+"vh", borderRadius:"1vw"}}></div> : null}
      {deleteConfirmButton}
    </div>;
}

const Offline = (props) => {
  return <div>
    <p>Offline</p>
    <hr></hr>
    <div style={widgetStyle}>
    The application is currently offline
    </div>
    <hr></hr>
    <Button onClick={()=>props.setView("init")} text="Try again"/>
  </div>
}

const WaitPosition = (props) => {
  if (!props.waitForPos)
    return null;
  return <div style={{fontSize:fontSize, position:"fixed", top:0, left:0, bottom:0, right:0, background:"white"}}>
    <div style = {{ position:"fixed", top:"40vmin", left:0, right:0, textAlign:"center"}}>
      <p>Waiting for position...</p>
      <hr></hr>
      <div style={widgetStyle}>
      Awaiting your choice of allowing browser positioning.<br></br>
      Should you allow this, the application will be sensitive to your position.<br></br>
      Your position will not be shared over the network and it will not be saved.
      </div>
      <hr></hr>
      <Button onClick={()=>props.setWaitForPos(false)} text="Cancel"/>
    </div>
  </div>
}


const Splash = (props) => {
  let [show, setShow] = React.useState(true);
  React.useEffect(()=>{
    const timer = setTimeout(() => setShow(false), 3000);
    return () => clearTimeout(timer);
  }, []);
  return show ? 
    <div style={{position:"fixed", top:0, left:0, bottom:0, right:0, background:"white"}}>
      <div style = {{position:"fixed", top:"40vmin", left:0, right:0, 
    textAlign:"center"}}> 
      <Logotype scaling={true}/>
      </div>
    </div> : null;
}

const UPDATE_INTERVAL_S = 15;

function App() {
  const queryParameters = new URLSearchParams(window.location.search)
  const heatmapdefault = queryParameters.get("heatmap") === "on";
  let gpsTimeStart = parseInt(queryParameters.get("start"));
  let gpsTimeLength = parseInt(queryParameters.get("length"));
  if (!gpsTimeStart) gpsTimeStart = 1;
  if (!gpsTimeLength) gpsTimeLength = 0xfffffff0;

  const [view, setView] = React.useState("init");
  const [account, setAccount] = React.useState(null);
  const [username, setUsername] = React.useState(null);
  const [rights, setRights] = React.useState(null);
  const [projection, setProjection] = React.useState(window.localStorage.getItem("projection"));
  const [error, setError] = React.useState(null);
  const [socketOpen, setSocketOpen] = React.useState(false);
  const [devices, setDevices] = React.useState([]);
  const [log, setLog] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [socket, setSocket] = React.useState(null);
  const [lastUpdateTime, setLastUpdateTime] = React.useState(null);
  const [zoom, setZoom] = React.useState(8);
  const [pendingAck, setPendingAck] = React.useState(null);
  const [pendingDis, setPendingDis] = React.useState(null);
  const [pendingLog, setPendingLog] = React.useState(true);
  const [pendingDevices, setPendingDevices] = React.useState(true);
  const [pendingUsers, setPendingUsers] = React.useState(true);
  const [trace, setTrace] = React.useState(null);
  const [selected, _setSelected] = React.useState("");
  const [pendingTrace, setPendingTrace] = React.useState(false);
  const [browserPos, setBrowserPos] = React.useState(null);
  const [waitForPos, setWaitForPos] = React.useState(true);
  const [heatmap, setHeapmap] = React.useState(heatmapdefault);

  // Ensure only devices in list can be set as selected
  const setSelected = (id) => {
    if ((!id) || (!devices)) {
      _setSelected("");
    }
    for (let i = 0; i < devices.length; ++i) {
      if (devices[i].id === id) {
        _setSelected(id);
        return;
      }
    }
    _setSelected("");
  }

  React.useEffect(() => {
    const timer = setInterval(()=>setSelected(()=>""), 10*1000);
    return ()=>clearInterval(timer);
  }, [selected]);

  const isAdmin = () => {
    return typeof(rights) === 'string' && rights.includes("A");
  }
  const isChecker = () => {
    return typeof(rights) === 'string' && rights.includes("C");
  }
  const showFilters = () => {
    return typeof(rights) === 'string' && rights.includes("F");
  }

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function(position) {
        setBrowserPos(position.coords);
        console.log("Got browser position: " + position.coords);
        setWaitForPos(false);
      },
      function(error) {
        console.error("Browser Location Error Code: " + error.code + " - " + error.message);
        setWaitForPos(false);
      }
    );
  }, []);

  // Block pinch, touch, wheel 
  React.useEffect(() => {
    const ignore = e => e.preventDefault();
    const ignoreTouch = e => { if (e.touches.length > 1) e.preventDefault() };
    document.body.style.zoom = "100%";
    window.addEventListener("wheel", ignore, {passive: false});
    window.addEventListener("touchmove", ignoreTouch, {passive: false});
    return ()=>{
      window.removeEventListener("", ignore);
      window.removeEventListener('touchmove', ignoreTouch);
    }
  }, []);

  React.useEffect(() => {
    if (view !== 'map')
      return ()=>{};
    const timer = setInterval(()=>{setPendingDevices(true); if(isAdmin) {setPendingLog(true);setPendingUsers(true);} if(trace) { setPendingTrace(true);} console.log("Interval update", new Date());}, UPDATE_INTERVAL_S*1000);
    console.log("Interval update restart", new Date());
    return ()=>clearInterval(timer);
  }, [view, pendingDevices, pendingLog]);

  React.useEffect(() => {
    let s = io(getWsApi());
    if (socket !== null)
      socket.close();
    setSocket(s);
    const onConnect = () => {
      console.log("Socket connected:", s.id);
      setSocketOpen(true); 
      if (view === "offline") {
        console.log("Got connection!")
        setView("init");
      }
      s.emit('token', {token:window.localStorage.getItem('token')});
    };
    const onDisconnect = () => {
        console.log("Socket closed.");
        setSocketOpen(false); 
        setPendingDevices(true);
        setPendingLog(true);
        if (isAdmin())
          setPendingUsers(true);
      };
    const onToken = (data) => {
      if (data.token === null || data.token === 'null')
        window.localStorage.removeItem("token");
      else
        window.localStorage.setItem("token", data.token);
      console.log("Got replacement token: " + data.token);
      setView('init');
    }
    const onUpdate = (data) => {
      console.log("Setting update pending");
      setPendingDevices(true);
      if (isAdmin()) {
        setPendingLog(true);
        setPendingUsers(true);
      }
    }

    console.log("account: ", account);

    if (!s)
      return ()=>{};
    s.on("connect", onConnect);
    s.on("disconnect", onDisconnect);
    s.on("token", onToken);
    s.on("update", onUpdate);
    return () => {
      s.off("connect", onConnect);
      s.off("disconnect", onDisconnect);
      s.off("token", onToken);
      s.off("update", onUpdate);
      s.close();
      s=null;
    };
  }, [socketOpen]);

  const views = {
    init: () => <Init setView={setView} setAccount={setAccount} setUsername={setUsername} setRights={setRights}/>,
    login: () => <Login setView={setView} setAccount={setAccount} setUsername={setUsername} setRights={setRights} setProjection={setProjection} setError={setError} socket={socket}/>,
    offline: () => <Offline setView={setView}/>,
    map: () => <div>{(isAdmin() && (trace !== null)) ? null : <Map key={"map"+username} onLogout={()=>{
                          socket.emit("token", {token:null})
                          setAccount(null); 
                          setUsername(null); 
                          setDevices([]);
                          setPendingDevices(false);
                          setUsers([]);
                          setPendingUsers(false);
                          setLog([]);
                          setPendingLog(false);
                          window.localStorage.removeItem("token");
                          window.localStorage.removeItem("projection");
                          window.localStorage.removeItem("rights");
                          window.localStorage.removeItem("current-checkpoint");
                          setView("init");}} 
                        devices={devices} 
                        users={users}
                        tracing={trace? true : false}
                        log={log}
                        isAdmin={isAdmin()}
                        isChecker={isChecker()}
                        showFilters={showFilters()}
                        waitForPos={waitForPos}
                        center={browserPos ? {lat:browserPos.latitude,lng:browserPos.longitude} : undefined}
                        lastUpdateTime={lastUpdateTime}
                        accountname={account ? account : ""}
                        projection={projection ? projection : "timespace"}
                        username={username}
                        pendingAck={pendingAck} setPendingAck={setPendingAck}
                        pendingDis={pendingDis} setPendingDis={setPendingDis}
                        zoom={zoom} setZoom={setZoom}
                        selected={selected} setSelected={(s)=>{setSelected(s);setPendingTrace(s!=="")}}
                        />}
                    {isAdmin() && trace && <Trace key="trace" devices={devices} trace={trace} zoom={zoom} setZoom={setZoom} 
                                     selected={selected} close={()=>{setTrace(null); setSelected("");}}
                                     projection={projection ? projection : "timespace"} isChecker={isChecker()} isAdmin={isAdmin()}/>}
                    {pendingDevices && <Fetch key={"fetch-devices"+pendingDevices} 
                      submit={true} 
                      onError={(e)=>{setPendingDevices(false);}}
                      onResult={(r)=>{
                        r.sort((a,b)=>{return (a && b && a.text && b.text) ? a.text.localeCompare(b.text) : -1});
                        setDevices(r);
                        setLastUpdateTime(new Date());
                        setPendingDevices(false);
                      }}
                      api={getApi()+`/devices?token=${ec(window.localStorage.getItem('token'))}`} /> }
                    {(isAdmin() && pendingLog) ? <Fetch key={"fetch-log"+pendingLog} 
                      submit={true} 
                      onError={(e)=>{ setPendingLog(false)}}
                      onResult={(r)=>{
                        setLog(r);
                        setPendingLog(false);
                      }}
                      api={getApi()+`/log?token=${ec(window.localStorage.getItem('token'))}`} /> : null}
                    {(isAdmin() && pendingUsers) ? <Fetch key={"users"+pendingUsers} 
                      submit={true} 
                      onError={(e)=>{ setPendingUsers(false)}}
                      onResult={(r)=>{
                        setUsers(r);
                        setPendingUsers(false);
                      }}
                      api={getApi()+`/users?token=${ec(window.localStorage.getItem('token'))}`}/> : null}
                    {pendingAck ? <Fetch key={"fetch-acknowledge"+pendingAck.id} 
                      submit={true}
                      onError={(e)=>{ setPendingAck(null); setError("Failed to acknowledge alarm")}}
                      onResult={(r)=>{ setPendingAck(null); }}
                      api={getApi()+`/acknowledge?token=${ec(window.localStorage.getItem('token'))}&devices=${ec(JSON.stringify([pendingAck.id]))}`} /> : null } 
                    {pendingDis ? <Fetch key={"fetch-disable"+pendingDis.id} 
                      submit={true}
                      onError={(e)=>{ setPendingDis(null); setError("Failed to disable alarm")}}
                      onResult={(r)=>{ setPendingDis(null); }}
                      api={getApi()+`/disable?token=${ec(window.localStorage.getItem('token'))}&devices=${ec(JSON.stringify([pendingDis.id]))}`} /> : null } 
                    {pendingTrace && selected && selected.length > 0 && <Fetch key={"fetch-trace"+selected} 
                      submit={true} 
                      onError={(e)=>{setPendingTrace(false);}}
                      onResult={(r)=>{
                        setPendingTrace(false);
                        setTrace(r);
                      }}
                      api={getApi()+`/trace?token=${ec(window.localStorage.getItem('token'))}&device=${heatmap ? "all" : ec(selected)}&start=${gpsTimeStart}&length=${gpsTimeLength})`} /> }
                </div>,
  };
  
  let body;
  if (error)
    body = <Error text={error} onBack={()=>{setError(null)}} />
  else
    body = views[view] ? views[view]() : <Error view={view} onBack={()=>setView("map")}/>;

  return (
    <div className="App" >
      <header className="App-header" style={{fontSize:"6vmin"}}>
        <div>
          {body}
          <WaitPosition waitForPos={waitForPos} setWaitForPos={setWaitForPos} />
          <Splash />
        </div>
      </header>
      { /*isDebug() && <div style={{position:"fixed", textAlign:"left", bottom:"2vh", left:"2vw", fontSize:"1.6vmin", color:"#c22c"}}>
        <p>View: {view} User: {username} Acct: {account}</p>
        <p>Token: {window.localStorage.getItem("token")} Api: {getApi()}</p>
        <p>Socket: {socketOpen ? "open" : "closed"} updatePending: {updatePending ?"true":"false"}</p>
  </div> */}
    </div>
  );
}

export default App;
