import React from 'react';
import { smallButtonStyle, buttonStyle, smallButtonStyle2 } from './styling';

const ClickOnceButtonBase = ({ style, text, onClick }) => {
  const [clicked, setClicked] = React.useState(false);

  // Re-enable after 15 seconds if no change in state from server
  React.useEffect(() => {
    let myInterval = setInterval(() => setClicked(false), 15000);
    return () => clearInterval(myInterval);
  }, []);

  return <button style={{ ...style, minWidth: "10vw" }} disabled={clicked} onClick={() => { setClicked(true); onClick(); }}>{text}</button>;
};

export const ClickOnceButton = (props) => <ClickOnceButtonBase {...props} style={smallButtonStyle} />;
export const ClickOnceButton2 = (props) => <ClickOnceButtonBase {...props} style={smallButtonStyle2} />;

const SureYesNoButtonBase = ({ ClickOnceButtonComponent, ...props }) => {
  const [show, setShow] = React.useState(props.open ? true : false);

  React.useEffect(() => {
    const timer = show ? setTimeout(() => setShow(false), 30 * 1000) : null;
    return () => timer && clearTimeout(timer);
  }, [show]);

  let onNo = props.onNo || (() => {});

  if (show) {
    return <div>
      {props.noText ? null : <ClickOnceButtonComponent text={props.text} onClick={() => { }} />}
      <div style={{ position: "fixed", left: 0, right: 0, top: 0, bottom: 0, background: "#0003" }} onClick={() => { onNo(); setShow(false); }}></div>
      <div style={{ background: "white", opacity: "100%", position: "fixed", fontSize: "1.8vmin", color: "black", borderStyle: "double", top: "40vh", right: "35vw", minHeight: "10vh", maxWidth: "30vw", minwidth: "30vw", padding: "2vmin" }}>
        Confirm {props.text}?
        <hr />
        <small>{props.info}</small>
        {props.info && <hr />}
        <button style={{ ...smallButtonStyle, minWidth: "10vw", padding: "0.2vmin", fontSize: "2vmin" }} onClick={(e) => { props.onClick(); e.stopPropagation(); setShow(false); }}>Yes</button>
        <button style={{ ...smallButtonStyle, minWidth: "10vw", float: "right", padding: "0.2vmin", fontSize: "2vmin" }} onClick={() => { onNo(); setShow(false); }}>No</button>
      </div>
    </div>
  }

  return <ClickOnceButtonComponent text={props.text} onClick={() => setShow(true)} />
};

export const SureYesNoButton = (props) => <SureYesNoButtonBase {...props} ClickOnceButtonComponent={ClickOnceButton} />;
export const SureYesNoButton2 = (props) => <SureYesNoButtonBase {...props} ClickOnceButtonComponent={ClickOnceButton2} />;